<template>
  <b-container class="page-registration">
    <div class="pt-2">
      <h1 class="text-deleev text-deleev-title text-center mb-0">Deleev</h1>
      <div v-if="current_center" class="text-center">
        <b-badge variant="info" class="p-2">{{centersGetCenter(current_center).name}}</b-badge>
      </div>
      <b-card border-variant="light">
        <b-form @input="onChanged" @submit.prevent="registration">
          <div class="mb-2">
            <label class="small text-muted mb-0">Nom</label>
            <b-form-input type="text" v-model="user.last_name" name="last_name" required :state="modelState('last_name')" autocomplete="off" size="sm" placeholder="nom..." />
          </div>
          <div class="mb-2">
            <label class="small text-muted mb-0">Prénom</label>
            <b-form-input type="text" v-model="user.first_name" name="first_name" required :state="modelState('first_name')" autocomplete="off" size="sm" placeholder="prénom..." />
          </div>
          <div class="mb-2">
            <label class="small text-muted mb-0">E-mail</label>
            <b-form-input type="email" v-model="user.email" name="email" required :state="modelState('email')" autocomplete="off" size="sm" placeholder="e-mail..." />
          </div>
          <div class="mb-2">
            <label class="small text-muted mb-0">Téléphone</label>
            <b-form-input type="text" v-model="user.phone_number" name="phone_number" required :state="modelState('phone_number')" autocomplete="off" size="sm" placeholder="téléphone..." />
            <b-form-text class="help-block font-italic">au format <strong class="text-info">0611223344</strong></b-form-text>
          </div>
          <div class="text-center mb-0">
            <b-button type="submit" size="sm" variant="primary" :disabled="!mChanged || $v.$invalid || submitStatus === 'updating'">Inscription</b-button>
            <b-form-invalid-feedback :state="submitStatus !== 'error'">
              <div v-if="submitErrorMessage">{{submitErrorMessage}}</div>
              <div>Re-vérifiez vos paramètres de connexion ou contactez l'administrateur.</div>
            </b-form-invalid-feedback>
          </div>
        </b-form>
      </b-card>
      <div class="text-center mt-3">
        <div class="smaller text-muted">Retour à la</div>
        <b-button variant="light" size="sm" :to="{ name: 'login' }">Connexion</b-button>
      </div>
    </div>

    <vue-overlay :overlay="overlay" @next-action="nextAction" />
  </b-container>
</template>

<script>
import store from '@/store'
import eventEmitter from '@/event-emitter'
import mErrors from '@/mixins/errors'
import ApiDeleevUsers from '@/api/deleev/users'
import ApiLabellevieTests from '@/api/labellevie/tests'
import VueOverlay from '@/components/helpers/overlay.vue'
import { required, minLength, email, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'Registration',
  components: {
    VueOverlay
  },
  computed: {
    current_center () {
      return (this.lookupCenter && this.lookupCenter.id) ? this.lookupCenter.id : 9
    }
  },
  data () {
    return {
      lookupCenter: store.getLookupCenter(),
      user: {
        last_name: '',
        first_name: '',
        email: '',
        phone_number: ''
      },
      mChanged: false,
      submitStatus: null,
      submitErrorMessage: '',
      changedKeys: {},
      overlay: { active: false, message: null, next: null, countDown: 0 }
    }
  },
  validations: {
    user: {
      email: { required, email },
      first_name: { required, minLength: minLength(2) },
      last_name: { required, minLength: minLength(2) },
      phone_number: { required, minLength: minLength(10), numeric }
    }
  },
  methods: {
    resetOverlay () {
      this.overlay.active = false
      this.overlay.message = null
      this.overlay.next = null
      this.overlay.countDown = 0
    },
    modelState (key) {
      if (!key) {
        return this.$v.$invalid ? false : (this.submitStatus === 'success' ? true : null)
      }
      if (this.$v.user[key]) {
        return this.$v.user[key].$invalid ? false : null
      }
      return null
    },
    onChanged (evt) {
      this.$v.$touch()
      this.mChanged = true
      this.changedKeys[evt.target.name] = true
    },
    async registration (evt) {
      evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'error'
        return
      }

      this.submitErrorMessage = ''
      this.submitStatus = 'updating'
      eventEmitter.emit('action:started')
      let response
      try {
        response = await ApiDeleevUsers.registerTestUser(this.user)
      } catch (e) {
        this.submitErrorMessage = mErrors.getAjaxErrorMessage(e)
      }

      if (response && response.id) {
        if (response.is_staff) {
          this.$router.push({ name: 'login' })
        } else {
          try {
            this.submitStatus = 'success'

            response.first_name = response.first_name || this.user.first_name
            response.last_name = response.last_name || this.user.last_name
            response.phone_number = response.phone_number || response.phone || this.user.phone_number
            response.favourite_center = this.current_center
            await ApiLabellevieTests.makeUserTester(response.id, { data: response })

            if (response.token) {
              store.saveToken(response.token, 7)
              window.location.href = '/' // '/tests/qcm'
            }
          } catch (e) {
            this.submitStatus = 'error'
          }
        }
      } else {
        this.submitStatus = 'error'
      }
      this.mChanged = false
      eventEmitter.emit('action:finished', { next: 'stay' })
    },
    nextAction () {
      this.resetOverlay()
    }
  }
}
</script>

<style scoped>
  .card {
    margin: 1rem auto 0 auto;
    max-width: 360px;
  }
  .card .input-group.input-group-sm small {
    width: 24px;
    line-height: 26px;
    text-align: center;
  }
</style>
