<template>
  <div class="mt-4">
    <p>Bienvenue dans la session de test <strong>La Belle Vie</strong></p>
    <!--
    <p>Vous allez devoir scanner <strong>un maximum de produits en un minimum de temps</strong>, sans faire d’erreurs. Le but est de scanner le <strong>code barre</strong> du produit. Certains produits se trouvent dans des cartons. Il ne faut pas scanner le code barre du carton mais celui du produit.</p>
    <div class="d-flex justify-content-center mb-3">
      <div class="w-50 ml-4 mr-1">
        <img src="/images/figure-1.png" class="w-100 rounded">
      </div>
      <div class="w-50 ml-1 mr-4">
        <img src="/images/figure-2.png" class="w-100 rounded">
      </div>
    </div>
    <p class="mb-0">Chaque produit possède un emplacement en trois parties, par exemple&nbsp;:&nbsp;<strong>101.3.5</strong></p>
    <div class="ml-3 mb-3">
      <div>- Le premier nombre <strong>101</strong> est le numéro de l’<strong>allée</strong></div>
      <div>- Le deuxième <strong>3</strong> est le numéro de l’<strong>étagère</strong></div>
      <div>- Le troisième <strong>5</strong> est le numéro de l’<strong>étage</strong> sur l’étagère</div>
    </div>
    <p>La zone du test est configurée de manière à ne jamais revenir en arrière. Les numéros des allées augmentent donc au fur et à mesure que vous progressez.</p>
    <p>Quand vous trouvez le produit, il faut scanner le code barre. Le produit suivant apparaitra alors. Il est important de reposer le produit à l’endroit exacte ou vous l’avez pris.</p>
    <p>Faites attention à bien scanner le code barre du produit et non le code QR s'il est présent sur le produit.</p>
    <div class="mx-3 mb-3">
      <img src="/images/figure-3.png" class="w-100 rounded">
    </div>
    <p>Attention, dans certains cas l’un des produits peut se trouver sur les étagères environnantes de celle indiquée.</p>
    <! --
    <p>En cas de produit <strong>périmé</strong> ou bien <strong>absent</strong>, il ne faut pas scanner le produit, mais il faudra cliquer sur les boutons appropriés.</p>
    -- >
    <p>En cas de produit <strong>absent</strong>, il faudra cliquer sur les bouton approprié.</p>
    <div class="text-center mb-4">
      <span class="bg-info text-white rounded p-2"><span class="text-uppercase">Absent</span></span>
      <! --
      <span class="bg-warning text-white rounded p-2 mx-2"><span class="text-uppercase">Abimé</span><small class="px-1">ou</small><span class="text-uppercase">sale</span></span>
      <span class="bg-danger text-white rounded p-2 ml-1"><span class="text-uppercase">Périmé</span></span>
      -- >
    </div>
    <p>Si vous êtes déconnecté durant le test, vous devez vous reconnecter avec les mêmes identifiants de l'inscription. Vous reprendrez alors le test où vous vous étiez arrêté.</p>
    <p>Durant ce test vous avancerez petit à petit dans la zone, vous ne devez jamais revenir en arrière.</p>
    <p>A vous de jouer.</p>
    -->
    <p>Rendez vous à la zone de départ et attendez le feu vert du responsable avant de lancer le test.</p>
    <p>Bon courage</p>
    <br />
    <div class="border rounded bg-light text-center mt-3 mb-5 py-2">
      <div class="smallx">
        <div class="text-muted">Tester mon PDA avant de commencer le test.</div>
        <strong>Scannez un code barre</strong>
      </div>
      <div class="mt-2 smallx">
        <span v-if="barcode"><span class="text-muted">Code barre&nbsp;:&nbsp;</span><strong class="text-primary">{{barcode}}</strong></span>
        <span v-else class="smallx font-italic">
          <b-spinner small type="grow"></b-spinner>
          <span class="text-muted ml-2">En attente de la lecture du code barre...</span>
        </span>
      </div>
    </div>
    <div class="nav-menu d-flex flex-column text-center mt-3 mb-2">
      <div class="text-muted small">Attendez le feu vert du responsable pour commencer</div>
      <b-button variant="danger" class="py-2 text-uppercase" :to="{ name: 'tests-trial' }">Commencer le test</b-button>
    </div>
  </div>
</template>

<script>
import eventEmitter from '@/event-emitter'

export default {
  name: 'TestsHome',
  created () {
    eventEmitter.on('barcode:scanned', this.onBarcodeScanned)
  },
  beforeDestroy () {
    eventEmitter.off('barcode:scanned', this.onBarcodeScanned)
  },
  data () {
    return {
      barcode: ''
    }
  },
  methods: {
    onBarcodeScanned (data) {
      this.barcode = data.barcode
    }
  }
}
</script>

<style scoped>
  .nav-menu {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  }
</style>
