'use strict'

import { ApiLabellevie, ApiUtils, ApiLabellevieConfig } from '../index'

export default {
  getProducts (params) {
    return ApiUtils.promiseGet(ApiLabellevie, '/team/tests/products', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  addProduct (data) {
    return ApiUtils.promisePost(ApiLabellevie, '/team/tests/products', data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  updateProduct (id, data) {
    return ApiUtils.promisePut(ApiLabellevie, `/team/tests/products/${id}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  updateProducts (data) {
    return ApiUtils.promisePut(ApiLabellevie, '/team/tests/products/bulk', data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  makeUserTester (userId, data) {
    return ApiUtils.promisePost(ApiLabellevie, `/team/tests/update-tester/${userId}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  deleteProducts (params) {
    return ApiUtils.promiseDelete(ApiLabellevie, `/team/tests/products/bulk`, { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  getUserTrial (userId, params) {
    return ApiUtils.promiseGet(ApiLabellevie, `/team/tests/users/${userId}`, { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  saveUserTrialLog (userId, data) {
    return ApiUtils.promisePost(ApiLabellevie, `/team/tests/users/${userId}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  getUserQcm (userId, qcmId) {
    return ApiUtils.promiseGet(ApiLabellevie, `/team/users/${userId}/qcm/${qcmId}`, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  },
  saveUserQcmResponses (userId, qcmId, responses) {
    return ApiUtils.promisePost(ApiLabellevie, `/team/users/${userId}/qcm/${qcmId}`, { responses: responses }, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
  }
}
